import { apiGet } from '@/utils/api';
import errorPopup from '@/utils/error-popup';

export default (userId, payload) => {
	const parameters = {
		dateFrom: payload.dateFrom !== null ? payload.dateFrom : '',
		dateTo: payload.dateTo !== null ? payload.dateTo : '',
		limit: payload.limit ? payload.limit : '',
		projectId: payload.projectId !== null ? payload.projectId : '',
		tagIds: payload.tagIds !== null ? payload.tagIds : '',
	}

	return apiGet(`statistics/project-totals/${userId}?dateFrom=${parameters.dateFrom}&dateTo=${parameters.dateTo}&projectId=${parameters.projectId}&tagIds=${parameters.tagIds}&limit=${parameters.limit}`)
		.then((response => response.data))
		.catch(error => errorPopup(error));
}