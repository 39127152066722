import dailyTotals from './daily-totals';
import projectTotals from './project-totals';
import summary from './summary';
import tagTotals from './tag-totals';
import topProjects from './top-projects';

export default {
	dailyTotals,
	projectTotals,
	summary,
	tagTotals,
	topProjects,
}
